//========================================================
// GLOBAL STYLES
//========================================================

:root
	font-size 16px
	@media(min-width: $tablet)
		font-size 20px

*,html,body
	box-sizing border-box

*:focus
	outline none

html
	width 100%
	height auto
	min-height 100%
	background-color var(--background)
	scroll-behavior smooth
	scroll-padding-top var(--nav-height)

body
	width 100%
	height auto
	min-height calc(100vh - 12px)
	margin 0
	position relative
	background var(--background)
	overflow-y auto
	overflow-x hidden
	-webkit-overflow-scrolling hidden
	scroll-behavior smooth
	// NICE BASIC GRID SIZING
	display grid
	border-bottom 12px solid #0057DE
	if fixednav
		grid-template-columns 1fr
		grid-template-rows 1fr auto
		gap 0px 0px
		grid-template-areas "." "."
	else
		if transparentnav
			grid-template-columns 1fr
			grid-template-rows 1fr auto
			gap 0px 0px
			grid-template-areas "." "."
		else
			grid-template-columns 1fr
			grid-template-rows auto 1fr auto
			gap 0px 0px
			grid-template-areas "." "." "."
	
main
	display block
	margin-left auto
	margin-right auto
	width 100%
	if transparentnav
		padding-top 0
	else
		padding-top var(--nav-height)

.site-main
	position relative
	display block
	width 100%
	clearfix()


// ADD THIS CLASS TO ANY OBJECT THAT NEEDS TO HAVE A MAX WIDTH
.contain
	max-width var(--content-width)
	margin-left auto
	margin-right auto

.contain--thin
	@media(min-width $tablet)
		padding-left 24px
		padding-right 24px

.full
	width 100%
	padding-right var(--hpad)
	padding-left var(--hpad)
	margin-left auto
	margin-right auto
	@media(min-width 1440px)
		max-width 1440px !important


// THIS CLASS MAKES OBJECTS IN MAX-WIDTH CONTAINERS BREAK OUT AND FILL SCREEN
.max-width,
.alignfull
	margin-left calc(-50vw + 50%)
	margin-right calc(-50vw + 50%)
	max-width 100vw
	width 100vw

.alignwide
	margin-left calc(-10vw)
	margin-right calc(-10vw)
	max-width 100vw

.loading
	visibility hidden
	
section
	width 100%
	position relative
	h1,h2
		margin-top 0

.hide
	display none !important

img
	max-width 100%
	height auto




// ASPECT RATIOS

.ar-16x9,
.ar-4x3,
.ar-3x1,
.ar-9x16,
.ar-3x4,
.ar-1x1
	padding 0
	@supports not (aspect-ratio 1/1)
		height auto
		position relative
		overflow hidden
		&:before
			height 0
			content ''
			display block
			padding-top 100%

.ar-16x9
	object-fit cover
	aspect-ratio 16/9
	@supports not (aspect-ratio 1/1)
		&:before
			padding-top 56.25% !important

.ar-4x3
	object-fit cover
	aspect-ratio 4/3
	@supports not (aspect-ratio 1/1)
		&:before
			padding-top 75% !important

.ar-3x1
	object-fit cover
	aspect-ratio 3/1
	@supports not (aspect-ratio 1/1)
		&:before
			padding-top 33.333333333333336% !important

.ar-9x16
	object-fit cover
	aspect-ratio 9/16
	@supports not (aspect-ratio 1/1)
		&:before
			padding-top 176.875% !important

.ar-3x4
	object-fit cover
	aspect-ratio 3/4
	@supports not (aspect-ratio 1/1)
		&:before
			padding-top 133.4375% !important

.ar-1x1
	object-fit cover
	aspect-ratio 1/1
	@supports not (aspect-ratio 1/1)
		&:before
			padding-top 100% !important





.scroll-down
	color inherit
	position relative
	text-decoration none
	font-size 0.8rem
	cursor pointer
	text-align center
	font-weight 600
	margin 2rem auto
	display block
	&:after
		content ''
		transition(all 0.5s ease)
		display block
		height 36px
		width 36px
		font-size 2.3em
		text-align center
		position absolute
		left 50%
		top 130%
		background-size contain
		transform( translateX(-50%) )

.noselect
	-webkit-touch-callout none
	-webkit-user-select none
	-khtml-user-select none
	-moz-user-select none
	-ms-user-select none
	user-select none

p.icon
	background #FDEADC
	border-radius(50%)
	width 60px
	height 60px
	padding 20px
	position relative
	display block
	overflow hidden
	@media(min-width:768px)
		width 90px
		height 90px
	img
		position absolute
		top 50%
		left 50%
		height 60% !important
		width auto !important
		padding 5px
		object-fit contain
		margin 0 !important
		transform(translate(-50%,-50%))

// USEFUL CLASSES
.no-m
	margin 0


