// ========================================================

// STYLUS KEYFRAMES

// INDEX:

//   1 - Stylus Key Frames

// =========================================================


@keyframes fadein
	0%
		opacity(0)
	80%
		opacity(0)
	100%
		opacity(1)

@keyframes bounce
	0%
		transform translateY(0em)
		-webkit-transform translateY(0em)
	60%
		transform translateY(0em)
		-webkit-transform translateY(0em)
	70%
		transform translateY(-.5em)
		-webkit-transform translateY(-.5em)
	80%
		transform translateY(.5em)
		-webkit-transform translateY(.5em)
	100%
		transform translateY(0em)
		-webkit-transform translateY(0em)

@keyframes spin
	0%
		-webkit-transform rotate(0deg)
		transform rotate(0deg)
	100%
		-webkit-transform rotate(360deg)
		transform rotate(360deg)