// ========================================================
// THEME VARIABLES & OPTIONS
// ========================================================

/* OPTIONS */
fixednav = true;
transparentnav = false;

/* SCREEN VARIABLES */
$large-mobile = 600px
$tablet = 768px
$laptop = 1024px
$desktop = 1240px
$large-desktop = 1600px

:root
	
	// COLOURS
	--white #FFFFFF
	--black #1B1B1F
	--grey #D8D8D8
	
	// PALETTE
	--pilotblue #0057DE
	--blue #8CB5BF
	--red #FF4A39
	--purple #C8A7C9
	--green #36896D
	--orange #FFAE50
	--cream  #FAF8F5
	--bookpaper #E1D9CD
	--mint #D2E4DB
	
	// COLOUR FUNCTIONS
	--color-dark var(--black)
	--color-light var(--white)
	--color-primary var(--blue)
	--color-secondary var(--teal)
	--color-success #36ED9C
	--color-error #FF3154
	
	--text-color var(--white)
	--link-color inherit
	--link-hover var(--white)

	// PAGE
	--background var(--black)
	
	// BUTTONS
	--button-bg var(--color-primary)
	--button-text var(--color-light)
	--button-padding 0.5rem 1rem
	
	// BUTTON AND FORM FIELD RADIUS
	--button-radius 15px
	
	// GRID
	--content-width calc( 100% - 2rem )
	--vpad 25px
	--hpad 25px
	--nav-height 80px

	@media( min-width: $tablet )
		--content-width 600px
		--nav-height 100px

	@media( min-width: $laptop )
		--content-width 700px

	@media( min-width: $desktop )
		--content-width 940px
		--nav-height 120px

	@media( min-width: $large-desktop )
		--content-width 1240px





