.cookie-consent-banner
  position fixed
  bottom 10px
  left 10px
  background-color var(--color-primary)
  padding 20px 30px
  max-width 400px
  border-radius 5px
  z-index 99
  transition all 1s ease
  &--close
    left: -100vw;
  &__info
    margin-bottom 20px
    p
      font-family var(--textFont)
      font-size var(--fs-b)
      color var(--color-secondary)
      margin 0
      &.large
        font-family var(--headingFont)
        font-size var(--fs-h4)
        margin-bottom 15px
  &__buttons
    display: flex;
    align-items: center;
    button
      &.button--reject
        background-color var(--color-error)
        margin-right 15px
      &.button--accept
        background-color var(--color-success)