// ========================================================
// STYLUS MIXINS
// =========================================================


// BUILT IN MIXINS
// embedurl() for SVGS https://stylus-lang.com/docs/bifs.html#embedurlpath-encoding


flex_box()
	display -webkit-box
	display -moz-box
	display -webkit-flex
	display -ms-flexbox
	display flex

inline_flex()
	display -webkit-inline-box
	display -moz-inline-box
	display -webkit-inline-flex
	display -ms-inline-flexbox
	display inline-flex


flex_direction(args = row)
	-webkit-flex-direction args
	-ms-flex-direction args
	flex-direction args
	if args == row-reverse
		-webkit-box-direction reverse
		-webkit-box-orient horizontal
		-moz-box-direction reverse
		-moz-box-orient horizontal
	if args == column
		-webkit-box-direction normal
		-webkit-box-orient vertical
		-moz-box-direction normal
		-moz-box-orient vertical
	if args == column-reverse
		-webkit-box-direction reverse
		-webkit-box-orient vertical
		-moz-box-direction reverse
		-moz-box-orient vertical
	if args == row
		-webkit-box-direction normal
		-webkit-box-orient horizontal
		-moz-box-direction normal
		-moz-box-orient horizontal

flex_wrap(args = wrap)
	-webkit-flex-wrap args
	if args == nowrap
		-ms-flex-wrap none
	if args == wrap
		-ms-flex-wrap args
	flex-wrap args

flex_grow(args = 1)
	-webkit-box-flex args
	-moz-box-flex args
	-webkit-flex-grow args
	-ms-flex args
	flex-grow args

flex_shrink(args = 1)
	-webkit-flex-shrink args
	-moz-flex-shrink args
	-ms-flex args
	flex-shrink args

flex_basis(args = auto)
	-webkit-flex-basis args
	flex-basis args

justify_content(args = flex-start)
	if args == flex-start
		-webkit-box-pack start
		-moz-box-pack start
		-ms-flex-pack start
	if args == flex-end
		-webkit-box-pack end
		-moz-box-pack end
		-ms-flex-pack end
	if args == space-between
		-webkit-box-pack justify
		-moz-box-pack justify
		-ms-flex-pack justify
	if args == space-around
		-ms-flex-pack distribute
	else
		-webkit-box-pack args
		-moz-box-pack args
		-ms-flex-pack args
	-webkit-justify-content args
	justify-content args

align_items(args = flex-start)
	if args == flex-start
		-webkit-box-align start
		-moz-box-align start
		-ms-flex-align start
	if args == flex-end
		-webkit-box-align end
		-moz-box-align end
		-ms-flex-align end
	else
		-webkit-box-align args
		-moz-box-align args
		-ms-flex-align args
	-webkit-align-items args
	align-items args

align_self(args = auto)
	-webkit-align-self args
	if args == flex-start
		-ms-flex-item-align start
	if args == flex-end
		-ms-flex-item-align end
	else
		-ms-flex-item-align args
	align-self args

align_content(args = flex-start)
	-webkit-align-content args
	if args == flex-start
		-ms-flex-line-pack start
	if args == flex-end
		-ms-flex-line-pack end
	else
		-ms-flex-line-pack args
	align-content args


box-sizing()
	-webkit-box-sizing arguments
	-moz-box-sizing arguments
	box-sizing arguments

box-shadow()
	-webkit-box-shadow arguments
	-moz-box-shadow arguments
	box-shadow arguments

textShadow()
	text-shadow 1px 1px 1px rgba(#000000, 0.20)

transition(args1,args2 = false,args3 = false)
	if args3
		-o-transition args1, args2, args3
		-moz-transition args1, args2, args3
		-webkit-transition args1, args2, args3
		transition args1, args2, args3
	else
		if args2
			-o-transition args1, args2
			-moz-transition args1, args2
			-webkit-transition args1, args2
			transition args1, args2
		else
			-o-transition args1
			-moz-transition args1
			-webkit-transition args1
			transition args1

transition-delay()
	transition-delay arguments
	-moz-transition-delay arguments
	-webkit-transition-delay arguments
	-o-transition-delay arguments

transform()
	-webkit-transform arguments
	-moz-transform arguments
	-o-transform arguments
	-ms-transform arguments
	transform arguments

transform-origin()
	-webkit-transform-origin arguments
	-ms-transform-origin arguments
	transform-origin arguments

border-radius()
	border-radius arguments
	-moz-border-radius arguments
	-webkit-border-radius arguments
	-o-border-radius arguments

animation()
	-webkit-animation arguments
	-moz-animation arguments
	-o-animation arguments
	-ms-animation arguments
	animation arguments

animation-name(args)
	-webkit-animation-name args
	-moz-animation-name args
	-o-animation-name args
	-ms-animation-name args
	animation-name args

animation-duration(args)
	-webkit-animation-duration args
	-moz-animation-duration args
	-o-animation-duration args
	-ms-animation-duration args
	animation-duration args

animation-timing-function(args)
	-webkit-animation-timing-function args
	-moz-animation-timing-function args
	-o-animation-timing-function args
	-ms-animation-timing-function args
	animation-timing-function args

animation-iteration-count(args)
	-webkit-animation-iteration-count args
	-moz-animation-iteration-count args
	-o-animation-iteration-count args
	-ms-animation-iteration-count args
	animation-iteration-count args

animation-fill-mode(args)
	-webkit-animation-fill-mode args
	-moz-animation-fill-mode args
	-o-animation-fill-mode args
	-ms-animation-fill-mode args
	animation-fill-mode args

animation-delay(args)
	-webkit-animation-delay args
	-moz-animation-delay args
	-o-animation-delay args
	-ms-animation-delay args
	animation-delay args

user-select(args)
	-webkit-touch-callout args
	touch-callout args
	-webkit-user-select args
	-moz-user-select args
	-ms-user-select args
	-o-user-select args
	user-select args

background-size()
	-webkit-background-size arguments
	background-size arguments
	
background-center()
	background-size(cover)
	background-repeat no-repeat
	background-position center center

tab-size(args)
	-moz-tab-size args
	-o-tab-size args
	tab-size args

appearance(args)
	-webkit-appearance args
	-moz-appearance args
	appearance args

background-linear-gradient(startPoint, startColor, startInterval, endColor, endInterval, deprecatedWebkitStartPoint = false, deprecatedWebkitEndPoint = false)
	background-color startColor
	if deprecatedWebkitStartPoint && deprecatedWebkitEndPoint
		background-image -webkit-gradient(linear, deprecatedWebkitStartPoint, deprecatedWebkitEndPoint, color-stop(startInterval, startColor), color-stop(endInterval, endColor))
	background-image -webkit-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
	background-image -moz-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
	background-image -ms-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
	background-image -o-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
	background-image linear-gradient(startPoint, startColor startInterval, endColor endInterval)

opacity(args)
	opacity args

clearfix()
	&:before
	&:after
		content ""
		display table
	&:after
		clear both

optimise()
	text-rendering optimizeLegibility
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale

centerMe()
	position absolute
	top 50%
	left 50%
	transform(translate(-50%,-50%))

sticky()
	position -webkit-sticky
	position -moz-sticky
	position -ms-sticky
	position -o-sticky
	position sticky
