/*
 * Theme Name:   Clay
 * Theme URI:    https://fiasco.design
 * Version:      4.0
 * Author:       Fiasco Design
 * Author URI:   https://fiasco.design
 * Tags:         design, animation, bristol
 * Run:          stylus -w -u nib -c wp-content/themes/clay/stylus/style.styl -o wp-content/themes/clay
 * Run all:      stylus -w -u nib wp-content/themes/clay/stylus/style.styl wp-content/themes/clay/stylus/editor-gutenberg.styl wp-content/themes/clay/stylus/editor-wysiwyg.styl -o wp-content/themes/clay

------------------------------------------

See our readme.md file for information about the configuration here.

------------------------------------------

OUR APPROACH TO STYLING
- Look over the designs. You should have a clear idea of which areas need to be modular, and which need to be fixed in place. If you're not clear, ask some more questions.
- To maximise reusability and flexibility, each part of the site should be built as a fully context neutral block which will retain all of it's styling even if dropped into another page or template.

------------------------------------------ 
*/



// Global Site Settings
@import "boilerplate.styl"
@import "mixins.styl"
@import "theme.styl"
@import "animations.styl"
@import "typography.styl"
@import "global.styl"

// Global Styling
@import "parts/*" 

// // IMPORT ALL GUTENBERG BLOCKS
// @import 'blocks/*'
