// ========================================================
// TYPOGRAPHY
// =========================================================

@font-face {
	font-family: 'New Century Schoolbook';
	src: url('~/src/fonts/new-century-schoolbook.woff2') format('woff2'),
		url('~/src/fonts/new-century-schoolbook.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

body 
	font-family: "Libre Franklin", sans-serif;
	font-optical-sizing: auto;
	font-weight: 900;
	font-style: normal;

:root
	
	// FONT FAMILIES
	--headingFont 'Libre Franklin', 'Helvetica', sans-serif;
	--textFont 'buenosaires', 'Helvetica', sans-serif;
	--monoFont Monaco, "Courier New", Courier, monospace;
	--iconFont 'fiascons' !important;

	// FONT SIZING
	--fs-h1 36px
	--fs-h2 30px
	--fs-h3 24px
	--fs-h4 20px
	--fs-b 16px
	--fs-small 12px
	--fs-caption var(--fs-small)
	
	@media(min-width:$tablet)
		--fs-h1 60px
		--fs-h2 48px
		--fs-h3 34px
		--fs-h4 24px
		--fs-b 16px
	
	@media(min-width:$laptop)
		--fs-h1 60px
		--fs-h2 48px
		--fs-h3 34px
		--fs-h4 24px
		--fs-b 16px


body
	font-family var(--textFont)
	font-size var(--fs-b)
	line-height 1.4em
	font-weight 400
	color var(--text-color)
	optimise()

p,li,blockquote,input,textarea
	font-size inherit

p
	margin 0 0 1.25em 0
	color inherit

h1,.h1
	font-size var(--fs-h1)

h2,.h2
	font-size var(--fs-h2)

h3,.h3
	font-size var(--fs-h3)

h4,.h4
	font-size var(--fs-h4)

h5,h6,.h5,.h6
	font-size var(--fs-b)

// ALL LINE-HEIGHTS SHOULD BE IN EMs

h1, h2, h3,
.h1, .h2, .h3
	font-family var(--headingFont)
	color inherit
	font-weight 600
	line-height 1em
	letter-spacing 0
	text-align inherit
	margin 0 0 1em 0
	optimise()

h2, h3,
.h2, .h3
	font-weight 600

h4, h5, h6,
.h4, .h5, .h6
	font-family var(--headingFont)
	color inherit
	letter-spacing 0
	text-align inherit
	margin 0 0 1em 0
	optimise()


a
	color inherit
	outline none
	font-weight inherit
	text-decoration underline
	&:visited
		color inherit
		outline none
	&:hover,
	&:focus
		text-decoration none
		outline none
		color inherit

button
	border none
	border-radius(0)

b, strong
	display inline
	font-family var(--textFont)
	font-size inherit
	line-height inherit
	font-weight 600

.strong
	font-weight 600

.caps
	text-transform uppercase

.center
	text-align center

.white
	color var(--white)
	p,ul
		color inherit

i,em,mark
	display inline

mark
	color var(--text-color)
	background var(--color-primary)
	padding 0 .3em

u
	display inline

ul,ol
	margin 0
	padding 0
	color inherit

li
	font-size var(--fs-b)
	line-height 1.2em
	margin 1em 0


blockquote
	width 100%
	max-width 1000px
	margin 1.25em auto
	padding-left 0
	position relative
	font-family var(--headingFont)
	font-weight 600
	font-style normal
	line-height 1.3em
	p
		max-width 1000px
		font-family var(--headingFont)
		font-weight 600
		font-style normal
		font-size var(--fs-h4)
		line-height 1.3em
	cite
		font-style normal
		font-size var(--fs-small)
		line-height 1.375em


label,
.label,
input,
textarea,
select
	font-family var(--textFont)

