/*
*  Footer
*/

:root
	--footer-bg linear-gradient(180deg, rgba(237, 113, 51, 0.4) 0%, rgba(237, 113, 51, 0) 100%), #F6AA72;
	--footer-text var(--text-color)
	--footer-link var(--link-color)

footer
	display block
	width 100%
	background var(--footer-bg)
	color var(--footer-text)
	clear both
	a
		text-decoration none
	a:link,
	a:visited
		color inherit
	p
		margin 0.5rem 0
		color inherit
	@media(min-width:$laptop)
		flex_box()
		flex_direction(column)
		align_content(stretch)
		justify_content(center)
		> div
			width 100%

.footer__content
	margin 0 auto
	@media(max-width: ($table - 1) )
		.footer__column
			margin-bottom 2.5rem

	@media(min-width:$tablet)
		flex_box()
		flex_direction()
		align_items(top)
		font-size 0.9rem
	
	&.footer__content--last
		flex-wrap wrap
		@media(min-width:$tablet)
			margin-top 2rem


.footer__contact
	text-align center
	margin 0 auto 2rem auto
	padding 4rem 30px
	position relative
	@media(min-width:$large-mobile)
		flex_box()
		flex_direction(column)
		align_items(center)
		justify_content(center)
	@media(min-width:$desktop)
		padding 2rem 30px
		height 100%
	@media(min-width:$large-desktop)
		max-width 1410px

.footer__menu
	flexbox()
	justify_content(space-between) 
	list-style none
	color var(--white)
	padding-left 0
	margin 0
	max-width 800px
	li
		display inline-block
		margin-right 2rem

.site-credit
	font-size var(--fs-small)
	font-weight 400
	margin 3em 0 0 0
	display inline-block
	@media(min-width:$tablet)
		margin-top 0
		margin-right auto !important
		order -1


.footer__column
	@media(max-width: ($tablet - 1))
		margin-bottom 2rem
	@media(min-width:$tablet)
		width 100%
		max-width 33%
		.caps
			margin-bottom 1rem
	@media(min-width:$laptop)
		width auto
		padding-right 100px

