/*
* Buttons
*
*/

// BASIC LINKS
.link
	display inline-block
	inline_flex()
	align_items(center)
	background-color transparent
	font-family var(--textFont)
	font-weight 500
	text-align center
	width auto
	text-decoration none
	border-bottom 2px solid var(--text-color)
	color inherit
	&.link--arrow
		&:before
			display none
		&:after
			content '>'
			color inherit
			font-family var(--iconFont)
			margin-left 0.3em
			line-height 0.5em
			margin-bottom 0.2em
	&.link--arrow.link--left
		&:before
			content '<'
			font-family var(--iconFont)
			margin-right 0.3em
			line-height 0.5em
			margin-bottom 0.2em

.link--disabled
	pointer-events none
	opacity(0.5)



// BUTTONS WITH BORDERS
.button,
a.button,
.button-small,
.button-large,
input[type='button'],
input[type='submit'],
button[type='submit']
	outline none
	border none
	cursor pointer
	display inline-block
	text-align center
	font-size inherit
	text-decoration none
	background-color var(--button-bg)
	color var(--white)
	padding var(--button-padding)
	box-shadow(none)
	border-radius( var(--button-radius) )
	&.button--arrow
		&:before
			display none
		&:after
			content '>'
			color inherit
			font-family var(--iconFont)
			margin-left 0.3em
			line-height 0.5em
			margin-bottom 0.2em
	&.button--arrow.button--left
		&:before
			content '<'
			font-family var(--iconFont)
			margin-right 0.3em
			line-height 0.5em
			margin-bottom 0.2em

.button-small
	padding 0.5em 1.5em

.button-large
	padding 1em 2.5em

.button--disabled
	cursor not-allowed !important
	opacity(0.5)



.button-outline
	outline none
	border none
	cursor pointer
	display inline-block
	text-align center
	padding 0.7em 2em
	font-size inherit
	text-decoration none
	background-color transparent
	color var(--text-color)
	border 1px solid var(--color-primary)
	box-shadow(none)
	border-radius( var(--button-radius) )
	&.button--arrow
		&:before
			display none
		&:after
			content '>'
			color inherit
			font-family var(--iconFont)
			margin-left 0.3em
			line-height 0.5em
			margin-bottom 0.2em
	&.button--arrow.button--left
		&:before
			content '<'
			font-family var(--iconFont)
			margin-right 0.3em
			line-height 0.5em
			margin-bottom 0.2em



// CALL TO ACTIONS ARE MULTI-ELEMENT LINKS
// (That usually have explained text)
.cta
	display block
	background var(--button-bg)




*::-webkit-media-controls-panel
	display none !important
	-webkit-appearance none
	-moz-appearance none

*::--webkit-media-controls-play-button
	display none !important
	-webkit-appearance none
	-moz-appearance none

*::-webkit-media-controls-start-playback-button
	display none !important
	-webkit-appearance none
	-moz-appearance none