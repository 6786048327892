:root
	--mouseX 0px
	--mouseY 0px
	// --line-height 0.95em
	--line-height 1em
	--font-size 0.85em
	@media(min-width 768px)
		--line-height 1em
		--font-size 1em
	@media(min-width 1024px)
		--line-height 1em

::selection
	background: #ffffff
	color var(--black)
::-moz-selection
	background: #ffffff
	color var(--black)

body.highlights-active
	h1
		color rgba(255,255,255,0.2)
		transition( color 0.3s ease-out )
	h1 > .purple
		color rgba(200, 167, 201,0.2)
		transition( color 0.3s ease-out )
	
	.highlight
		&:hover
			.overlay
				opacity 1
				visibility visible
				transition( all 0.1s ease-out )

main
	padding 4vw
	display flex
	flex-direction column
	justify-content space-between
	height 100%
	@media(min-width 768px)
		padding 2vw
	h1
		font-size 10vw
		line-height var(--line-height)
		text-transform uppercase
		transition( color 0.3s ease-out )
		margin-bottom 20px
		@media(min-width 768px)
			font-size 6.6vw
		@media(min-width 1024px)
			font-size 5.5vw
	a:link
		position relative
		text-decoration underline
	.sign-off
		font-size 20px
		text-align right
		color #C1C1C1
		font-family "New Century Schoolbook", serif;
		margin-top auto
		a
			margin-left 0.3em
			&:first-child
				margin-left 1em

.overlay
	--background #8CB5BF
	--color #242424
	position fixed
	top calc( var(--mouseY) + 10px )
	left calc( var(--mouseX) + 10px )
	padding 15px
	border-radius 10px
	background var(--background)
	box-shadow 0px 14px 24px rgba(0, 0, 0, 0.25), 0px 1px 26px rgba(0, 0, 0, 0.25)
	color var(--color)
	font-size 18px
	line-height 1.44em
	text-transform none
	font-weight 100
	font-family "New Century SchoolBook LT Std"
	width 296px
	height auto
	pointer-events none
	opacity 0
	visibility hidden
	z-index 99
	transition( all 0.1s ease-out )

.overlay--right
	.overlay
		left auto
		left calc( var(--mouseX) - 300px )

.overlay--bottom
	.overlay
		top auto
		top calc( var(--mouseY) - 300px )

.overlay--thoughts
	--background var(--purple)

.overlay--pilot
	--background var(--pilotblue)
	--color #FAF8F5




.highlight
	&:hover
		color rgba(255,255,255,1)
		transition( color 0.3s ease-out )

strong, b
	font-family "Libre Franklin", Helvetica, sans-serif;


.blue
	color var(--pilotblue)

.purple
	color var(--purple)
	transition( color 0.3s ease-out )

.red
	color var(--red)

.green
	color var(--green)

.icon
	display inline-block
	width var(--font-size)
	height var(--font-size)
	flex-shrink 0
	background no-repeat center center transparent
	background-image url('~/src/img/tmo.png')
	background-size contain
	vertical-align middle
	margin -0.3em 0.1em
	line-height var(--line-height)
	overflow hidden
	position relative
	top -0.08em

.kindred
	background-image url('~/src/img/kindred-logo.png')

.pilot
	background-image url('~/src/img/pilot-logo.png')

.thoughts
	background-image url('~/src/img/thoughts-on-purpose-logo.png')


.social-icon
	display inline-block
	width 22px
	height 22px
	flex-shrink 0
	background no-repeat center center transparent
	background-size contain
	vertical-align middle
	margin -0.3em 1em
	line-height 1em
	overflow hidden
	position relative
	color transparent
	transform( scale(1) )
	transition( transform 0.3s ease-out )
	&:hover
		color transparent
		transform( scale(1.1) )
		transition( transform 0.3s ease-out )
	&.twitter
		background-image url('~/src/img/twitter.svg')
	&.linkedin
		background-image url('~/src/img/linkedin.svg')
	&.facebook
		background-image url('~/src/img/linkedin.svg')
	&.email
		background-image url('~/src/img/email.svg')