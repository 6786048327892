//
// NAV
//


.nav
	
	// PRIMARY MENU COLOR
	--menu-primary var(--text-color)

	display block
	width 100%
	height auto
	z-index 99
	background-color var(--color-light)

	if fixednav
		position fixed
		top 0
		left 0
	else
		if transparentnav
			position absolute
		else
			position relative

	@media(max-width: ($tablet - 1) )
		background-color transparent
		.nav__mobile
			opacity(0)
			display none
	@media(min-width: $tablet )
		background-color transparent
		bottom auto
		transform(translateY(0px))
		transition: transform 0.3s ease, background-color 0.3s

.nav__content
	position relative
	@media(min-width: $tablet )
		flex_box()
		flex_direction(row)
		flex_wrap(nowrap)
		align_items(center)
		height var(--nav-height)


.nav__overlay
	position relative


// MOBILE NAV TRAY
.nav__mobile
	// color var(--color-light)
	width 100%
	text-align left
	max-width 80%
	margin 0 auto
	padding 40px
	li
		margin 2rem 0
	a:link,
	a:visited
		color inherit
	> li
		font-weight 600
	.sub-menu
		li
			font-weight 500
			&:before
				content '- '
	@media(min-width:$tablet)
		display none !important

// DESKTOP NAV
.nav__desktop
	display none
	color var(--menu-primary)
	width 100%
	text-align right
	margin 0 auto
	li
		vertical-align top
		margin 0
		font-size var(--fs-small)
		a
			display block
			padding 1.5rem 1.2rem
			text-decoration none
			@media(min-width:$large-desktop)
				padding 1.8rem 1.8rem
	a:link,
	a:visited
		color var(--menu-primary)
	> li
		font-weight 600
	.menu-item-has-children
		height 124px
		overflow visible
		&:hover
			background #FDEADC
			.sub-menu
				opacity(1)
				pointer-events auto
				background #FDEADC
			> a
				color var(--link-hover)
				transition(all 0.3s ease)
	.sub-menu
		opacity(0)
		pointer-events none
		li
			font-weight 500
			display block
			margin 0
			text-align left
			a
				transition(all 0.3s ease)
				padding 1rem 2rem
				display block
				&:before
					content '- '
	@media(min-width:$tablet)
		display block
		li
			display inline-block

.menu-item-has-children
	> a
		&:after
			content ' +'


.nav__button
	position fixed
	top 10px
	right 10px
	@media(min-width:$tablet)
		top 30px
		right 40px

// MENU BUTTON USING TEXT
.nav__close
	color var(--menu-primary) !important
	position absolute
	top 10px
	right 20px
	margin-top 1rem
	font-size 0.9rem
	font-weight 600
	&:before
		content 'Menu'
	@media(min-width:$tablet)
		display none

// MENU BUTTON USING ICON
.nav__icon
	background-color transparent
	color var(--menu-primary)
	padding 0
	text-transform uppercase
	position absolute
	top 5px
	right 5px
	z-index 11
	cursor pointer
	width 28px
	height 24px
	transition(top 0.3s ease)
	div
		width 100%
		height auto
		display block
		vertical-align middle
		span
			background-color var(--menu-primary)
			display block
			height 3px
			margin-bottom 6px
			-ms-transform-origin center center
			-o-transform-origin center center
			-webkit-transform-origin center center
			-moz-transform-origin center center
			transform-origin center center
			transition(all 0.3s ease)
		span:first-of-type
			-ms-transform-origin 8px 6px
			-o-transform-origin 8px 6px
			-webkit-transform-origin 8px 6px
			-moz-transform-origin 8px 6px
			transform-origin 8px 6px
		span:last-of-type
			transition(all 0.1s ease)
	b
		opacity(1)
		line-height 9px
		text-align center
		text-align justify
		text-justify inter-word
		letter-spacing 1px
		transition(all 0.2s 0s ease)
		font-size 9px
		width 100%
		display block
		font-family inherit
	@media(min-width:768px)
		display none

.site-logo
	display block
	top 0
	left 0
	width 80px
	margin 20px 0
	svg
		width 100%
		height auto
	@media(min-width:$tablet)
		width 133px



// THE NAV--LIGHT CLASS INVERTS THE NAV COLOUR ON DESKTOP
.nav--light
	
	@media(min-width: $tablet)
	
		.nav
			--menu-primary var(--color-light)
		
		.site-logo
			svg
				path,
				polygon
					fill var(--menu-primary)



.nav--open
	
	// MENU BUTTON USING TEXT
	.nav__close
		color var(--menu-primary)
		&:before
			content 'Close'
	
	// MENU BUTTON USING ICON
	.nav__icon
		div
			span
				background-color var(--menu-primary)
				transform(rotate(-45deg))
			span:first-of-type
				transform(rotate(45deg))
			span:nth-child(2)
				transform rotate(-45deg) translate(5px, -3px)
			span:last-of-type
				opacity(0)
	
	// OPEN MOBILE WINDOW
	@media(max-width: ($tablet - 1) )
		overflow hidden
		.nav
			background-color var(--color-secondary)
			bottom 0
			transition(all 0.3s ease)
			.nav__mobile
				display block
				opacity(1)


// THE NAV--SHOW CLASS IS USED FOR REVEALING NAVIGATION ON SCROLL EVENTS
.nav--show
	@media(min-width:$tablet)
		.nav
			background-color var(--color-primary)
			transform(translateY(0%))
			transition: transform 0.3s ease, background-color 0.3s
	&.nav--light
		.site-logo
			svg
				path
					fill var(--menu-primary)
	.site-logo
		margin 32px 0
	.nav__desktop li a
		padding 2rem 1.2rem 1.5rem 1.2rem
		@media(min-width:1640px)
			padding 2rem 1.8rem 1.5rem 1.8rem


// THE NAV--HIDE CLASS IS FOR HIDING NAVIGATION ON SCROLL EVENTS
.nav--hide
	@media(min-width:$tablet)
		.nav
			transform(translateY(-100%))
			transition: transform 0.3s ease, background-color 0.3s




