
// GRID
// - If you see some funny business with the grid sizes, it's often to do with the overflow of the parent container
// - The grid has no outside gutters, but these can be added using our normal .full class just like any other element.

:root
	--cols: 6
	--gutters calc( var(--cols) - 1 )
	--grid-gap: 8px
	--min-col-width calc( (100% - ( var(--grid-gap) * var(--gutters) ) ) / var(--cols) )
	
	@media( min-width: $tablet )
		--grid-gap: 24px
	
	@media( min-width: $laptop )
		--cols: 12


.g
	display: grid
	grid-template-columns: repeat(auto-fit, minmax(var(--min-col-width), 1fr))
	grid-gap: var(--grid-gap)

// G-FILL creates a different grid
// - This creates a different style of grid where the 12 columns do not always fit to the width of the container
// - It's unlikely we'll use this at first
.g-fill
	--min-col-width: calc((100% / var(--cols)) - var(--grid-gap))
	display: grid
	grid-template-columns: repeat(auto-fill, minmax(var(--min-col-width), 1fr))
	grid-gap: var(--grid-gap)


// CREATE COLUMN CLASSES
for num in 1 .. 12
	.c{num}
		--cols: num
		grid-column: var(--colstart, auto) / span var(--cols, 12)

for num in 1 .. 12
	.c{num}
		&-t
			// Tablet sizes
			@media (min-width: $tablet)
				--cols: num
		&-l
			// Laptop sizes
			@media (min-width: $laptop)
				--cols: num
		&-d
			// Desktop sizes
			@media (min-width: $desktop)
				--cols: num

// CREATING COLUMN START CLASSES
// - So we can push columns across
for num in 1 .. 12
	.cs{num}
		--colstart: num

		&-t
			// Tablet sizes
			@media (min-width: $tablet)
				--colstart: num

		&-l
			// Laptop sizes
			@media (min-width: $laptop)
				--colstart: num

		&-d
			// Desktop sizes
			@media (min-width: $desktop)
				--colstart: num

// Most common use case is pushing to opposite edges
.csend
	--colstart: calc(13 - var(--cols))



